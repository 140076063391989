/** Onboarding */
export const SET_PAYMATE_MERCHANT_ID = "SET_PAYMATE_MERCHANT_ID";
export const SET_BUSINESS_ADDRESS = "SET_BUSINESS_ADDRESS";
export const SET_BUSINESS_TYPE = "SET_BUSINESS_TYPE";
export const SET_BUSINESS_NAME = "SET_BUSINESS_NAME";
export const SET_DOING_BUSINESS_AS = "SET_DOING_BUSINESS_AS";
export const SET_BUSINESS_INDUSTRY = "SET_BUSINESS_INDUSTRY";
export const SET_BUSINESS_WEBSITE = "SET_BUSINESS_WEBSITE";
export const SET_PRODUCT_DESCRIPTION = "SET_PRODUCT_DESCRIPTION";
export const SET_BUSINESS_REPRESENTATIVE = "SET_BUSINESS_REPRESENTATIVE";
export const SET_BUSINESS_REGISTRATION_NUMBER =
  "SET_BUSINESS_REGISTRATION_NUMBER";
export const SET_BUSINESS_INCORPORATION_DATE =
  "SET_BUSINESS_INCORPORATION_DATE";
export const SET_DTI_BUSINESS_CERTIFICATE = "SET_DTI_BUSINESS_CERTIFICATE";
export const SET_CERTIFICATE_OF_INCORPORATION =
  "SET_CERTIFICATE_OF_INCORPORATION";
export const SET_ARTICLES_OF_INCORPORATION = "SET_ARTICLES_OF_INCORPORATION";
export const SET_ARTICLES_OF_PARTNERSHIP = "SET_ARTICLES_OF_PARTNERSHIP";
export const SET_BY_LAWS = "SET_BY_LAWS";
export const SET_SECRETARY_CERTIFICATE = "SET_SECRETARY_CERTIFICATE";
export const SET_BUSINESS_OWNERS = "SET_BUSINESS_OWNERS";
export const SET_STATEMENT_DESCRIPTOR = "SET_STATEMENT_DESCRIPTOR";
// export const SET_STATEMENT_PREFIX = 'SET_STATEMENT_PREFIX';
export const SET_SUPPORT_PHONE = "SET_SUPPORT_PHONE";
export const SET_BANK_NAME = "SET_BANK_NAME";
export const SET_BANK_ACCOUNT_NAME = "SET_BANK_ACCOUNT_NAME";
export const SET_BANK_ACCOUNT_NUMBER = "SET_BANK_ACCOUNT_NUMBER";
export const SET_USE_2FA_SMS = "SET_USE_2FA_SMS";
export const SET_USE_2FA_AUTHENTICATOR = "SET_USE_2FA_AUTHENTICATOR";
export const SET_2FA_AUTHENTICATOR_SECRET = "SET_2FA_AUTHENTICATOR_SECRET";
export const SET_MOA_ACCEPTANCE = "SET_MOA_ACCEPTANCE";
export const SET_ONBOARDING_STATUS = "SET_ONBOARDING_STATUS";

/** User */
export const SET_USER_ID = "SET_USER_ID";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const SET_USER_FIRST_NAME = "SET_USER_FIRST_NAME";
export const SET_USER_LAST_NAME = "SET_USER_LAST_NAME";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const SET_USER_PHONE = "SET_USER_PHONE";
export const SET_USER_ROLE = "SET_USER_ROLE";
export const SET_USER_2FA_SMS_ENABLED = "SET_USER_2FA_SMS_ENABLED";
export const SET_USER_2FA_AUTHENTICATOR_ENABLED =
  "SET_USER_2FA_AUTHENTICATOR_ENABLED";
export const SET_USER_AUTHENTICATOR_SECRET = "SET_USER_AUTHENTICATOR_SECRET";
export const SET_USER_AUTHENTICATOR_BACKUP = "SET_USER_AUTHENTICATOR_BACKUP";

export interface AddressType {
  line1?: string | null;
  line2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country: string;
}

export const initialAddress: AddressType = {
  line1: null,
  line2: null,
  city: null,
  state: null,
  country: "PH",
  zip: null,
};

export interface DateType {
  day?: string | null;
  month?: string | null;
  year?: string | null;
}

export interface UserType {
  id: string;
  email: string;
  name: string;
}

export interface PhoneType {
  prefix?: string;
  phone?: string;
}

export enum UserRoles {
  ADMINISTRATOR = "administrator",
  DEVELOPER = "developer",
  ANALYST = "analyst",
  SUPPORT = "support",
  READ_ONLY = "viewer",
}
