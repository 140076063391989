import {
  AddressType,
  DateType,
  SET_2FA_AUTHENTICATOR_SECRET,
  SET_ARTICLES_OF_INCORPORATION,
  SET_ARTICLES_OF_PARTNERSHIP,
  SET_BANK_ACCOUNT_NAME,
  SET_BANK_ACCOUNT_NUMBER,
  SET_BANK_NAME,
  SET_BUSINESS_ADDRESS,
  SET_BUSINESS_INCORPORATION_DATE,
  SET_BUSINESS_INDUSTRY,
  SET_BUSINESS_NAME,
  SET_BUSINESS_OWNERS,
  SET_BUSINESS_REGISTRATION_NUMBER,
  SET_BUSINESS_REPRESENTATIVE,
  SET_BUSINESS_TYPE,
  SET_BUSINESS_WEBSITE,
  SET_BY_LAWS,
  SET_CERTIFICATE_OF_INCORPORATION,
  SET_DOING_BUSINESS_AS,
  SET_DTI_BUSINESS_CERTIFICATE,
  SET_MOA_ACCEPTANCE,
  SET_ONBOARDING_STATUS,
  SET_PAYMATE_MERCHANT_ID,
  SET_PRODUCT_DESCRIPTION,
  SET_SECRETARY_CERTIFICATE,
  SET_STATEMENT_DESCRIPTOR,
  // SET_STATEMENT_PREFIX,
  SET_SUPPORT_PHONE,
  SET_USE_2FA_AUTHENTICATOR,
  SET_USE_2FA_SMS
} from './types'

export enum BusinessType {
  AUB = 'aub',
  INDIVIDUAL = 'individual',
  SOLE_PROP = 'sole_prop',
  ONE_PERSON_CORP = 'one_person_corp',
  PARTNERSHIP = 'partnership',
  COMPANY = 'company',
  NONPROFIT = 'nonprofit'
}

export enum UploadType {
  VALID_ID = 'valid_ids',
  SEC_CERTIFICATE = 'sec_certificate_of_incorporations',
  BY_LAWS = 'by_laws_documents',
  ARTICLES_OF_INCORPORATION = 'articles_of_incorporations',
  SECRETATRY_CERTIFICATE = 'notarized_secretary_certificates',
  DTI_CERTIFICATE = 'dti_certificates',
  ARTICLES_OF_PARTNERSHIP = 'articles_of_partnerships',
}

export interface DocumentType {
  id: string;
  filename: string | null;
  url: string;
}

export interface PersonDocumentsType {
  files?: DocumentType[];
  idNumber?: string;
  idType?: string;
}

export interface BusinessRepresentativeType {
  firstName?: string;
  lastName?: string;
  // email?: string;
  dob: DateType;
  address?: AddressType;
  // phone?: string;
  documents?: PersonDocumentsType;
}

export interface BusinessDocumentType {
  files?: DocumentType[];
  details?: string;
}

export interface BusinessOwnerType {
  id: string;
  firstName: string;
  lastName: string;
  dob: DateType;
  address: AddressType;
}

export interface MoaAcceptanceType {
  ip?: string | null;
  dateAccepted?: number | null;
  userAgent?: string | null;
  location?: string | null;
}

export interface OnboardingState {
  paymateMerchantId?: string | null;
  businessAddress: AddressType;
  businessType: BusinessType;
  businessName?: string | null;
  doingBusinessAs?: string | null;
  businessIndustry?: string | null;
  businessWebsite?: string | null;
  productDescription?: string | null;
  representative: BusinessRepresentativeType | null;
  businessRegistrationNumber?: string | null;
  businessIncorporationDate: DateType;
  dtiBusinessCertificate?: DocumentType[];
  certificateOfIncorporation?: DocumentType[];
  articlesOfIncorporation?: DocumentType[];
  articlesOfPartnership?: DocumentType[];
  byLaws?: DocumentType[];
  secretaryCertificate?: DocumentType[];
  owners: BusinessOwnerType[] | null;
  statementDescriptor?: string | null;
  // statementPrefix?: string | null;
  supportPhone?: string | null;
  bankName?: string | null;
  bankAccountNumber?: string | null;
  bankAccountName?: string | null;
  use2faSMS: boolean;
  use2faAuthenticator: boolean;
  authenticatorSecret?: string | null;
  moaAcceptance: MoaAcceptanceType;
  status?: string | null;
}

export type OnboardingAction =
  | {
    type:
    | 'SET_PAYMATE_MERCHANT_ID'
    | 'SET_BUSINESS_NAME'
    | 'SET_DOING_BUSINESS_AS'
    | 'SET_BUSINESS_INDUSTRY'
    | 'SET_BUSINESS_WEBSITE'
    | 'SET_PRODUCT_DESCRIPTION'
    | 'SET_BUSINESS_REGISTRATION_NUMBER'
    | 'SET_STATEMENT_DESCRIPTOR'
    | 'SET_STATEMENT_PREFIX'
    | 'SET_SUPPORT_PHONE'
    | 'SET_BANK_NAME'
    | 'SET_BANK_ACCOUNT_NUMBER'
    | 'SET_BANK_ACCOUNT_NAME'
    | 'SET_2FA_AUTHENTICATOR_SECRET'
    | 'SET_ONBOARDING_STATUS';
    payload?: string | null
  }
  | {
    type:
    | 'SET_DTI_BUSINESS_CERTIFICATE'
    | 'SET_CERTIFICATE_OF_INCORPORATION'
    | 'SET_ARTICLES_OF_INCORPORATION'
    | 'SET_ARTICLES_OF_PARTNERSHIP'
    | 'SET_BY_LAWS'
    | 'SET_SECRETARY_CERTIFICATE';
    payload?: DocumentType[]
  }
  | {
    type:
    | 'SET_USE_2FA_SMS'
    | 'SET_USE_2FA_AUTHENTICATOR';
    payload: boolean
  }
  | {
    type: 'SET_BUSINESS_TYPE';
    payload: BusinessType
  }
  | { type: 'SET_BUSINESS_ADDRESS'; payload: AddressType }
  | { type: 'SET_BUSINESS_REPRESENTATIVE'; payload: BusinessRepresentativeType | null }
  | { type: 'SET_BUSINESS_INCORPORATION_DATE'; payload: DateType }
  | { type: 'SET_BUSINESS_OWNERS'; payload: BusinessOwnerType[] | null }
  | { type: 'SET_MOA_ACCEPTANCE'; payload: MoaAcceptanceType }

export const initialState: OnboardingState = {
  paymateMerchantId: null,
  businessAddress: {
    line1: null,
    line2: null,
    city: null,
    state: null,
    country: 'PH',
    zip: null
  },
  businessType: BusinessType.AUB,
  businessName: null,
  doingBusinessAs: null,
  businessIndustry: null,
  businessWebsite: null,
  productDescription: null,
  representative: null,
  businessRegistrationNumber: null,
  businessIncorporationDate: { day: null, month: null, year: null },
  dtiBusinessCertificate: [],
  certificateOfIncorporation: [],
  articlesOfIncorporation: [],
  articlesOfPartnership: [],
  byLaws: [],
  secretaryCertificate: [],
  owners: null,
  statementDescriptor: null,
  // statementPrefix: null,
  supportPhone: null,
  bankName: null,
  bankAccountNumber: null,
  bankAccountName: null,
  use2faSMS: false,
  use2faAuthenticator: false,
  authenticatorSecret: null,
  moaAcceptance: {
    ip: null,
    dateAccepted: null,
    userAgent: null
  },
  status: 'not_submitted',
}

const onboardingReducer = (state: OnboardingState, action: OnboardingAction): OnboardingState => {
  switch (action.type) {
    case SET_PAYMATE_MERCHANT_ID:
      return {
        ...state,
        paymateMerchantId: action.payload
      }
    case SET_BUSINESS_ADDRESS:
      return {
        ...state,
        businessAddress: action.payload
      }
    case SET_BUSINESS_TYPE:
      return {
        ...state,
        businessType: action.payload
      }
    case SET_BUSINESS_NAME:
      return {
        ...state,
        businessName: action.payload
      }
    case SET_DOING_BUSINESS_AS:
      return {
        ...state,
        doingBusinessAs: action.payload
      }
    case SET_BUSINESS_INDUSTRY:
      return {
        ...state,
        businessIndustry: action.payload
      }
    case SET_BUSINESS_WEBSITE:
      return {
        ...state,
        businessWebsite: action.payload
      }
    case SET_PRODUCT_DESCRIPTION:
      return {
        ...state,
        productDescription: action.payload
      }
    case SET_BUSINESS_REPRESENTATIVE:
      return {
        ...state,
        representative: action.payload
      }
    case SET_BUSINESS_REGISTRATION_NUMBER:
      return {
        ...state,
        businessRegistrationNumber: action.payload
      }
    case SET_BUSINESS_INCORPORATION_DATE:
      return {
        ...state,
        businessIncorporationDate: action.payload
      }
    case SET_DTI_BUSINESS_CERTIFICATE:
      return {
        ...state,
        dtiBusinessCertificate: action.payload
      }
    case SET_CERTIFICATE_OF_INCORPORATION:
      return {
        ...state,
        certificateOfIncorporation: action.payload
      }
    case SET_ARTICLES_OF_INCORPORATION:
      return {
        ...state,
        articlesOfIncorporation: action.payload
      }
    case SET_ARTICLES_OF_PARTNERSHIP:
      return {
        ...state,
        articlesOfPartnership: action.payload
      }
    case SET_BY_LAWS:
      return {
        ...state,
        byLaws: action.payload
      }
    case SET_SECRETARY_CERTIFICATE:
      return {
        ...state,
        secretaryCertificate: action.payload
      }
    case SET_BUSINESS_OWNERS:
      return {
        ...state,
        owners: action.payload
      }
    case SET_STATEMENT_DESCRIPTOR:
      return {
        ...state,
        statementDescriptor: action.payload
      }
    /* case SET_STATEMENT_PREFIX:
      return {
        ...state,
        statementPrefix: action.payload
      } */
    case SET_SUPPORT_PHONE:
      return {
        ...state,
        supportPhone: action.payload
      }
    case SET_BANK_NAME:
      return {
        ...state,
        bankName: action.payload
      }
    case SET_BANK_ACCOUNT_NUMBER:
      return {
        ...state,
        bankAccountNumber: action.payload
      }
    case SET_BANK_ACCOUNT_NAME:
      return {
        ...state,
        bankAccountName: action.payload
      }
    case SET_USE_2FA_SMS:
      return {
        ...state,
        use2faSMS: action.payload
      }
    case SET_USE_2FA_AUTHENTICATOR:
      return {
        ...state,
        use2faAuthenticator: action.payload
      }
    case SET_2FA_AUTHENTICATOR_SECRET:
      return {
        ...state,
        authenticatorSecret: action.payload
      }
    case SET_MOA_ACCEPTANCE:
      return {
        ...state,
        moaAcceptance: action.payload
      }
    case SET_ONBOARDING_STATUS:
      return {
        ...state,
        status: action.payload
      }
    default:
      return state;
  }
}

export default onboardingReducer;